import { Theme } from '@mui/material/'

export const FormGroupSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'no-wrap',
  gap: '5px'
})

export const BoxTextFieldSx = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center'
})

export const TextFieldSx = (theme: Theme) => ({
  flexGrow: 1,
  '& .MuiInput-underline': {
    fontSize: theme.typography.pxToRem(13),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: theme.typography.pxToRem(13),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  '& .MuiFormHelperText-root': {
    marginInline: '2px',
    color: 'red'
  }
})

export const ButtonCommitFormSx = {
  minWidth: 0,
  width: '100%',
  marginBottom: '20px'
}

export const TypographyFormResultSx = {
  marginBottom: '15px',
  fontSize: '12px'
}

export const TextFieldIconSx = {
  color: '#74c3ea',
  mr: 2,
  my: 0.5,
  fontSize: '25px'
}

export const TextFieldPasswordVisibilityIcon = {
  margin: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '20px'
  }
}

export const FormControlLabelSx = {
  display: 'inline-block',
  '& .MuiFormControlLabel-label': {
    fontSize: '12px'
  }
}

export const FormHelperTextSx = {
  fontWeight: 700,
  color: 'green'
}

import { configureStore } from '@reduxjs/toolkit'
import ToggleLoginPageFormReducer from '../features/toggle-loginpage-form/toggleLoginPageFormSlice'
import ToggleSidenavReducer from '../features/toggle-sidenav/toggleSidenavSlice'

const Store = configureStore({
  reducer: {
    login_page_form: ToggleLoginPageFormReducer,
    sidenav: ToggleSidenavReducer
  }
})

export default Store

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu'
import AccountMenu from './components/account-menu'
import AppLogo from 'src/assets/brand/new_lnp_logo.png'
import {
  AppBar,
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import {
  AppBarSx,
  ToolbarSx,
  StackWebTitleLogoSx,
  TypographyAppTitleSx,
  StackGreetingsAvatarSx,
  TypographyGreetingsSx,
  AvatarSx
} from './sx-props'
import { toggleSidenav } from 'src/setup/features/toggle-sidenav/toggleSidenavSlice'

type ReduxStoreType = {
  sidenav: {
    isSidenavOpen: boolean
  }
}

const TopNav = () => {
  const isSidenavOpen = useSelector(
    (store: ReduxStoreType) => store.sidenav.isSidenavOpen
  )
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickAccountMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseAccountMenu = () => setAnchorEl(null)

  const user = JSON.parse(localStorage.getItem('lnp_user')!)

  return (
    <>
      <AppBar position="fixed" sx={AppBarSx}>
        <Toolbar sx={(theme) => ToolbarSx(theme, isSidenavOpen)}>
          <Stack sx={StackWebTitleLogoSx}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleSidenav())}
              edge="start"
              sx={{ margin: '0' }}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
            <img src={AppLogo} alt="Website Logo" />
            <Typography variant="h1" sx={TypographyAppTitleSx}>
              Ang Ligaya ng Panginoon
            </Typography>
          </Stack>

          <Stack sx={StackGreetingsAvatarSx}>
            <Typography component="span" sx={TypographyGreetingsSx}>
              Welcome, {user?.userInfo?.firstName}
            </Typography>
            <Tooltip title="LNP Account" placement="bottom-end">
              <IconButton onClick={handleClickAccountMenu}>
                <Avatar src="/broken-image.jpg" sx={AvatarSx} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <AccountMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleCloseAccountMenu}
        user={user}
      />
    </>
  )
}

export default TopNav

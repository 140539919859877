import { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import AppProviders from './setup/app-providers'
import RequireAuth from './setup/auth'
import LoginPage from './pages/login'
import MainPage from './pages/main'
const CoursesNewAndEditForm = lazy(
  () => import('./pages/courses/components/new_and_edit_form')
)
const CoursesShow = lazy(() => import('./pages/courses/components/show'))
const EmployeesNewAndEditForm = lazy(
  () => import('./pages/employees/components/new_and_edit_form')
)
const FormsNewAndEditForm = lazy(
  () => import('./pages/forms/components/new_and_edit_form')
)
const FormsShow = lazy(() => import('./pages/forms/components/show'))
const MemberListsShow = lazy(
  () => import('./pages/member_lists/components/show')
)
const MemberListsCourseNewForm = lazy(
  () => import('./pages/members/components/show/components/tabs/components/courses/components/new_form')
)
const ActiveMembersPage = lazy(() => import('./pages/active_members'))
const AttendancesPage = lazy(() => import('./pages/attendances'))
const AttendancesNewAndEditForm = lazy(
  () => import('./pages/attendances/components/new_and_edit_form')
)
const AttendanceShow = lazy(() => import('./pages/attendances/components/show'))
const AttendanceNamesPage = lazy(() => import('./pages/attendance_names'))
const CategoriesPage = lazy(() => import('./pages/categories'))
const ChangeSectorsPage = lazy(() => import('./pages/change_sectors'))
const ChurchAffiliatesPage = lazy(() => import('./pages/church_affiliates'))
const ClassificationsPage = lazy(() => import('./pages/classifications'))
const CoursesPage = lazy(() => import('./pages/courses'))
const EmployeesPage = lazy(() => import('./pages/employees'))
const EntrypointsPage = lazy(() => import('./pages/entrypoints'))
const FaqsPage = lazy(() => import('./pages/faqs'))
const FormsPage = lazy(() => import('./pages/forms'))
const GroupsPage = lazy(() => import('./pages/groups'))
const GroupsNewAndEditForm = lazy(
  () => import('./pages/groups/components/new_and_edit_form')
)
const GroupsShow = lazy(() => import('./pages/groups/components/show'))
const HomePage = lazy(() => import('./pages/home'))
const MembersPage = lazy(() => import('./pages/members'))
const MembersNewAndEditForm = lazy(
  () => import('./pages/members/components/new_and_edit_form')
)
const MembersShow = lazy(() => import('./pages/members/components/show'))
const MemberCourseNewForm = lazy(
  () =>
    import(
      './pages/members/components/show/components/tabs/components/courses/components/new_form'
    )
)
const MembersListPage = lazy(() => import('./pages/member_lists'))
const NoMatchPage = lazy(() => import('./pages/no-match'))
const PropheciesPage = lazy(() => import('./pages/prophecies'))
const PropheciesNewAndEditForm = lazy(
  () => import('./pages/prophecies/components/new_and_edit_form')
)
const PropheciesShow = lazy(() => import('./pages/prophecies/components/show'))
const ProphecyEventNamesPage = lazy(
  () => import('./pages/prophecy_event_names')
)
const RecordHistoriesPage = lazy(() => import('./pages/record_histories'))
const SchedulesPage = lazy(() => import('./pages/schedules'))
const SchedulesShow = lazy(() => import('./pages/schedules/components/show'))
const SchedulesNewAndEditForm = lazy(
  () => import('./pages/schedules/components/new_and_edit_form')
)
const SectorsPage = lazy(() => import('./pages/sectors'))
const ServiceTypesPage = lazy(() => import('./pages/service_types'))
const StatusesPage = lazy(() => import('./pages/statuses'))
const BirthdaysPage = lazy(() => import('./pages/birthdays'))
const NewAnnouncement = lazy(
  () =>
    import(
      './pages/schedules/components/show/components/participants_table/components/new_announcement'
    )
)

const App = () => {
  return (
    <AppProviders>
      <Suspense fallback={<MainPage loadingRoute={true} />}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/*" element={<NoMatchPage />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <MainPage />
              </RequireAuth>
            }
          >
            <Route index element={<HomePage />} />
            <Route path="members" element={<MembersPage />} />
            <Route path="members/new" element={<MembersNewAndEditForm />} />
            <Route path="members/:memberId" element={<MembersShow />} />
            <Route
              path="members/:memberId/edit"
              element={<MembersNewAndEditForm />}
            />
            <Route
              path="members/:memberId/new_course"
              element={<MemberCourseNewForm />}
            />
            <Route path="attendances" element={<AttendancesPage />} />
            <Route
              path="attendances/new"
              element={<AttendancesNewAndEditForm />}
            />
            <Route
              path="attendances/:attendanceId"
              element={<AttendanceShow />}
            />
            <Route
              path="attendances/:attendanceId/edit"
              element={<AttendancesNewAndEditForm />}
            />
            <Route path="groups" element={<GroupsPage />} />
            <Route path="groups/new" element={<GroupsNewAndEditForm />} />
            <Route
              path="groups/:groupId/edit"
              element={<GroupsNewAndEditForm />}
            />
            <Route path="groups/:groupId" element={<GroupsShow />} />
            <Route path="change_sectors" element={<ChangeSectorsPage />} />
            <Route path="member_lists" element={<MembersListPage />} />
            <Route
              path="member_lists/:memberId"
              element={<MemberListsShow />}
            />
            <Route
              path="member_lists/:memberId/new_course"
              element={<MemberListsCourseNewForm />}
            />
            <Route path="schedules" element={<SchedulesPage />} />
            <Route path="schedules/new" element={<SchedulesNewAndEditForm />} />
            <Route path="schedules/:scheduleId" element={<SchedulesShow />} />
            <Route
              path="schedules/:scheduleId/edit"
              element={<SchedulesNewAndEditForm />}
            />
            <Route
              path="schedules/:scheduleId/new_announcement"
              element={<NewAnnouncement />}
            />
            <Route path="courses" element={<CoursesPage />} />
            <Route path="courses/new" element={<CoursesNewAndEditForm />} />
            <Route
              path="courses/:courseId/edit"
              element={<CoursesNewAndEditForm />}
            />
            <Route path="courses/:courseId" element={<CoursesShow />} />
            <Route path="prophecies" element={<PropheciesPage />} />
            <Route
              path="prophecies/new"
              element={<PropheciesNewAndEditForm />}
            />
            <Route path="prophecies/:prophecyId" element={<PropheciesShow />} />
            <Route
              path="prophecies/:prophecyId/edit"
              element={<PropheciesNewAndEditForm />}
            />
            <Route path="forms" element={<FormsPage />} />
            <Route path="forms/new" element={<FormsNewAndEditForm />} />
            <Route
              path="forms/:formId/edit"
              element={<FormsNewAndEditForm />}
            />
            <Route path="forms/:formId" element={<FormsShow />} />
            <Route path="faqs" element={<FaqsPage />} />
            <Route
              path="church_affiliates"
              element={<ChurchAffiliatesPage />}
            />
            <Route path="classifications" element={<ClassificationsPage />} />
            <Route path="categories" element={<CategoriesPage />} />
            <Route path="entrypoints" element={<EntrypointsPage />} />
            <Route path="statuses" element={<StatusesPage />} />
            <Route path="service_types" element={<ServiceTypesPage />} />
            <Route path="sectors" element={<SectorsPage />} />
            <Route path="attendance_names" element={<AttendanceNamesPage />} />
            <Route
              path="prophecy_event_names"
              element={<ProphecyEventNamesPage />}
            />
            <Route path="active_members" element={<ActiveMembersPage />} />
            <Route path="employees" element={<EmployeesPage />} />
            <Route path="employees/new" element={<EmployeesNewAndEditForm />} />
            <Route path="record_histories" element={<RecordHistoriesPage />} />
            <Route path="sections" element={<SectorsPage />} />
            <Route path="birthdays" element={<BirthdaysPage />} />
          </Route>
        </Routes>
      </Suspense>
    </AppProviders>
  )
}

export default App

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Avatar,
  Button,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { Settings } from '@mui/icons-material'
import {
  MenuSx,
  PaperPropsSx,
  BoxMenuSx,
  BoxUserInfoSx,
  TypographyUserNameSx,
  TypographyUserEmailSx,
  MenuItemSx,
  BoxLiLogoutSx,
  ButtonLogoutSx
} from './sx-props'
import ConfirmationModal from 'src/common/modal/confirmation'
import DisplayModal from 'src/common/modal/display'
import ChangePassword from './components/change_password'

type PropTypes = {
  anchorEl: null | HTMLElement
  open: boolean
  handleClose: () => void
  user: {
    userInfo: {
      firstName: string
      lastName: string
      email: string | null
    }
  }
}

const AccountMenu = (props: PropTypes) => {
  const navigate = useNavigate()
  const { anchorEl, open, handleClose, user } = props
  const [logoutConfirmationModal, setLogoutConfirmationModal] = useState(false)
  const [changePasswordModal, setChangePasswordModal] = useState(false)

  const toggleLogoutConfirmationModal = () =>
    setLogoutConfirmationModal(!logoutConfirmationModal)

  const toggleChangePasswordModal = () =>
    setChangePasswordModal(!changePasswordModal)

  const handleLogout = () => {
    // clear session
    localStorage.clear()
    navigate('/login', { replace: true })
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          elevation: 0,
          sx: PaperPropsSx
        }}
        sx={MenuSx}
      >
        <Box component="li" sx={BoxMenuSx}>
          <Avatar />
          <Box sx={BoxUserInfoSx}>
            <Typography component="span" sx={TypographyUserNameSx}>
              {`${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`}
            </Typography>
            <Typography component="span" sx={TypographyUserEmailSx}>
              {`${user?.userInfo?.email ?? 'no email provided'}`}
            </Typography>
          </Box>
        </Box>
        <MenuItem sx={MenuItemSx} onClick={toggleChangePasswordModal}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <Box component="li" sx={BoxLiLogoutSx}>
          <Button
            color="inherit"
            variant="outlined"
            onClick={toggleLogoutConfirmationModal}
            sx={ButtonLogoutSx}
          >
            Sign Out
          </Button>
        </Box>
      </Menu>
      <ConfirmationModal
        open={logoutConfirmationModal}
        title="Sign out"
        item=""
        content="Are you sure you want to sign out?"
        toggle={toggleLogoutConfirmationModal}
        confirm={handleLogout}
      />
      <DisplayModal
        title="Change Password"
        isModalOpen={changePasswordModal}
        toggleModal={toggleChangePasswordModal}
        width="xs"
      >
        <ChangePassword toggleChangePasswordModal={toggleChangePasswordModal} />
      </DisplayModal>
    </>
  )
}

export default AccountMenu

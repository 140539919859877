import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { Theme } from '@mui/material'
import TopNav from './components/top-nav'
import SideNav from './components/side-nav'
import LinearProgressIndicator from 'src/common/linear-progress'

const BoxMainSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh'
})

const BoxOutletSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  gap: '5px',
  minHeight: 'fit-content',
  maxWidth: '100%',
  flexGrow: 1,
  marginTop: '64px',
  padding: '6px',
  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
    padding: '14px',
    paddingTop: '84px'
  }
})

type mainPageType = {
  loadingRoute?: boolean
}

const MainPage = (props: mainPageType) => {
  return (
    <Box sx={BoxMainSx}>
      <TopNav />
      <SideNav />
      <Box sx={BoxOutletSx}>
        {props.loadingRoute && <LinearProgressIndicator />}
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainPage

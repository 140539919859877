import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          padding: 0
        }
      }
    }
  },
  typography: {
    fontFamily:
      '"Google Sans", Roboto, RobotoDraft, Helvetica, Arial, sans-serif'
  }
})

type Props = {
  children: React.ReactNode
}

const OverrideCssBaseline = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </ThemeProvider>
  )
}

export default OverrideCssBaseline

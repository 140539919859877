import { Theme } from '@mui/material'

export const MenuSx = {
  '& .MuiPaper-root': {
    maxWidth: 'none',
    minWidth: '260px'
  }
}

export const PaperPropsSx = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 40,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  }
}

export const BoxMenuSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  paddingBlock: '10px',
  paddingInline: '40px',
  [theme.breakpoints.up('md')]: {
    gap: '15px',
    paddingBlock: '15px'
  },
  '& .MuiAvatar-root': {
    width: '50px',
    height: '50px',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '70px',
      height: '70px'
    }
  }
})

export const BoxUserInfoSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export const TypographyUserNameSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 600,
  textTransform: 'capitalize',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(15)
  }
})

export const TypographyUserEmailSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(10),
  color: '#686868',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(12)
  }
})

export const MenuItemSx = (theme: Theme) => ({
  borderBlock: '1px solid #cecece',
  paddingBlock: '10px',
  fontSize: theme.typography.pxToRem(11),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(13)
  }
})

export const BoxLiLogoutSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingBlock: '10px',
  [theme.breakpoints.up('md')]: {
    paddingBlock: '15px'
  }
})

export const ButtonLogoutSx = (theme: Theme) => ({
  paddingBlock: '3px',
  paddingInline: '20px',
  fontSize: theme.typography.pxToRem(11),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(12)
  }
})

import { Theme } from '@mui/material'

export const DrawerSx = (theme: Theme, isSideNavOpen: boolean) => ({
  maxWidth: '260px',
  [theme.breakpoints.up('md')]: {
    width: `${isSideNavOpen ? '100%' : 'auto'}`,
    flexGrow: `${isSideNavOpen ? 1 : 0}`
  },
  '& > .MuiPaper-root': {
    backgroundColor: '#6cc1ea'
  }
})

export const ToolbarSx = (theme: Theme) => ({
  minWidth: '260px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '70px'
  }
})
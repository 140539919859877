import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { AccountCircle } from '@mui/icons-material'
import { Box, Button, FormGroup, TextField } from '@mui/material'
import {
  BoxTextFieldSx,
  TextFieldIconSx,
  TextFieldSx,
  FormGroupSx
} from '../shared-sx/sx-props'

const initialValues = {
  username: ''
}

const validationSchema = Yup.object({
  username: Yup.string().required('Required')
})

type ValuesTypes = {
  username: string
}
type OnSubmitPropsTypes = {
  resetForm: () => void
  setSubmitting: (params: boolean) => void
}

type OnSubmitParams = {
  values: ValuesTypes
  onSubmitProps: OnSubmitPropsTypes
}

const onSubmit = (props: OnSubmitParams) => {
  const { values } = props
  console.log('Values - ', values)
}

type FieldTypes = {
  name: string
  value: string
  onChange: () => void
  onBlur: () => void
}

type MetaTypes = {
  error: string
  touched: boolean
}

type FieldProps = {
  field: FieldTypes
  meta: MetaTypes
}

const ForgotPasswordForm = () => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, onSubmitProps) =>
          onSubmit({ values, onSubmitProps })
        }
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <Form>
              <FormGroup sx={FormGroupSx}>
                <Field name="username">
                  {(props: FieldProps) => {
                    const {
                      field,
                      meta: { error, touched }
                    } = props

                    return (
                      <Box sx={BoxTextFieldSx}>
                        <AccountCircle sx={TextFieldIconSx} />
                        <TextField
                          variant="standard"
                          label="Username"
                          id="username"
                          type="text"
                          sx={TextFieldSx}
                          helperText={error && touched ? error : ' '}
                          autoFocus
                          {...field}
                        />
                      </Box>
                    )
                  }}
                </Field>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Send reset instructions
                </Button>
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ForgotPasswordForm

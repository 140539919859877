import { Theme } from '@mui/material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const DialogSx = (isSearchDialog: boolean) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    gap: '5px'
  },
  ...(isSearchDialog
    ? {
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          marginTop: '43px'
        }
      }
    : null)
})

const DialogTitleSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(14),
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(20)
  }
})

const DialogContentSx = (theme: Theme) => ({
  paddingTop: 0,
  fontSize: theme.typography.pxToRem(14)
})

const StackSx = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}

type DisplayModalProps = {
  title: string
  isModalOpen: boolean
  toggleModal: () => void
  children: React.ReactNode
  width?: 'xs' | 'sm' | 'md' | 'lg'
  isSearchDialog?: boolean
}

const DisplayModal = (props: DisplayModalProps) => {
  const {
    title,
    isModalOpen,
    toggleModal,
    children,
    width = 'xs',
    isSearchDialog = false
  } = props

  return (
    <Dialog
      open={isModalOpen}
      onClose={toggleModal}
      maxWidth={width}
      fullWidth
      sx={() => DialogSx(isSearchDialog)}
    >
      <Stack sx={StackSx}>
        <DialogTitle sx={DialogTitleSx}>{title}</DialogTitle>
        <IconButton onClick={toggleModal} sx={{ marginRight: '16px' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Stack>
      <DialogContent sx={DialogContentSx}>{children}</DialogContent>
    </Dialog>
  )
}

export default DisplayModal

import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { BackdropSx, TypographyLoadingMessageSx } from './sx-props'

type Props = {
  open: boolean
  message?: string
}

const Spinner = (props: Props) => {
  const { open, message } = props

  return (
    <Backdrop open={open} sx={BackdropSx}>
      <CircularProgress color="primary" />
      <Typography sx={TypographyLoadingMessageSx}>{message}</Typography>
    </Backdrop>
  )
}

export default Spinner

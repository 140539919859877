import { Theme } from '@mui/material'

export const ListItemMainSx = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  paddingLeft: 0,
  alignItems: 'flex-start',
  color: '#fff',

  '& > a': {
    display: 'block',
    width: '100%',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    textDecoration: 'none',
    color: '#fff'
  },

  '& > .active': {
    backgroundColor: '#6ad6c355'
  }
}

export const ListItemButtonMainSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: '10px',
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px',
  paddingBlock: '2px',
  paddingRight: '10px',
  paddingLeft: '8px',
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 900,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(14)
  }
})

export const ListSx = (theme: Theme) => ({
  width: '100%',
  paddingTop: '0px',
  paddingBottom: '0px',
  '& > .MuiListItem-root': {
    padding: 0,
    '& .MuiButtonBase-root': {
      paddingLeft: '30px',
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          fontSize: theme.typography.pxToRem(13.5),
          fontWeight: 500
        }
      }
    }
  }
})

export const ListItemTextMainSx = (theme: Theme) => ({
  color: '#fff',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '10px',
  '& > .MuiTypography-root': {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 900,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  }
})

export const ListItemButtonSx = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  columnGap: '10px',
  padding: '0',
  paddingRight: '10px',
  paddingLeft: '8px',
  borderTopRightRadius: '20px',
  borderBottomRightRadius: '20px'
}

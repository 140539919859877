import {
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {
  FormGroupInputSx,
  FormGroupButtonSx,
  StackHeaderSx,
  IconSx,
  TypographyLabelSx,
  TextFieldSx,
  TextFieldPasswordVisibilityIconSx
} from './sx-props'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { useState } from 'react'

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required').min(6, 'Must be 6 characters'),
  confirmNewPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'New password must match')
    .min(6, 'Must be 6 characters')
})

type FieldTypes = {
  name: string
  value: string
  onChange: () => void
  onBlur: () => void
}

type MetaTypes = {
  error: string
  touched: boolean
}

type FieldProps = {
  field: FieldTypes
  meta: MetaTypes
}

type changePasswordPropType = {
  toggleChangePasswordModal: () => void
}

const ChangePassword = (props: changePasswordPropType) => {
  const { toggleChangePasswordModal } = props
  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [showPassword3, setShowPassword3] = useState(false)

  const togglePasswordVisibility1 = () => setShowPassword1(!showPassword1)
  const togglePasswordVisibility2 = () => setShowPassword2(!showPassword2)
  const togglePasswordVisibility3 = () => setShowPassword3(!showPassword3)

  const inputFields = [
    {
      name: 'currentPassword',
      label: 'Current Password',
      toggle: togglePasswordVisibility1,
      showPassword: showPassword1
    },
    {
      name: 'newPassword',
      label: 'New Password',
      toggle: togglePasswordVisibility2,
      showPassword: showPassword2
    },
    {
      name: 'confirmNewPassword',
      label: 'Confirm New Password',
      toggle: togglePasswordVisibility3,
      showPassword: showPassword3
    }
  ]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, onSubmitProps) =>
        console.log('Values to submit', values, onSubmitProps)
      }
    >
      {(formik) => {
        const { isValid, isSubmitting } = formik

        return (
          <Form>
            <Stack sx={StackHeaderSx}>
              <LockOpenIcon sx={IconSx} />
              <Typography variant="body1" sx={TypographyLabelSx}>
                Make sure to remember your password
              </Typography>
            </Stack>
            <FormGroup sx={FormGroupInputSx}>
              {inputFields.map((inputField, idx) => {
                return (
                  <Field name={inputField.name} key={idx}>
                    {(props: FieldProps) => {
                      const {
                        field,
                        meta: { error, touched }
                      } = props

                      return (
                        <TextField
                          id="password"
                          label={inputField.label}
                          type={inputField.showPassword ? 'text' : 'password'}
                          variant="standard"
                          sx={TextFieldSx}
                          helperText={error && touched ? error : ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={inputField.toggle}
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                  sx={TextFieldPasswordVisibilityIconSx}
                                >
                                  {inputField.showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          {...field}
                        />
                      )
                    }}
                  </Field>
                )
              })}
            </FormGroup>
            <FormGroup sx={FormGroupButtonSx}>
              <Button
                type="reset"
                onClick={toggleChangePasswordModal}
                color="error"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
              >
                Change
              </Button>
            </FormGroup>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ChangePassword

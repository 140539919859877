const useSession = () => {
  const lnp_user = JSON.parse(
    localStorage.getItem('lnp_user')!,
    (key, value) => {
      if (key === 'token') {
        return {
          ...value,
          expiryDate: new Date(value.expiryDate)
        }
      }
      return value
    }
  )


  const isSessionExpired = lnp_user
    ? new Date() > lnp_user?.token?.expiryDate
    : true

  return isSessionExpired
}

export default useSession

import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider } from 'react-redux'
import Store from '../store'
import OverrideCssBaseline from 'src/OverrideCssBaseline'

const queryClient = new QueryClient()

type AppProvidersProps = {
  children: React.ReactNode
}

const AppProviders = (props: AppProvidersProps) => {
  const { children } = props

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Provider store={Store}>
          <OverrideCssBaseline>{children}</OverrideCssBaseline>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default AppProviders

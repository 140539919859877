import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  formToDisplay: 'sign-in'
}

const toggleLoginPageFormSlice = createSlice({
  name: 'login_page_form',
  initialState,
  reducers: {
    toggleLoginPageForm: (state, action) => {
      state.formToDisplay = action.payload
    }
  }
})

export default toggleLoginPageFormSlice.reducer
export const { toggleLoginPageForm } = toggleLoginPageFormSlice.actions

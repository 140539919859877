import { Theme } from '@mui/material'

export const BackdropSx = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  zIndex: 1300 + 3
})

export const TypographyLoadingMessageSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 700,
  color: '#fff'
})

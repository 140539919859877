import { Theme } from '@mui/material/'
import LoginPageBackground from 'src/assets/images/background.jpg'

export const StackSx = {
  minHeight: '100vh',
  backgroundImage: `url(${LoginPageBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed'
}

export const AppBarSx = {
  boxShadow: 'none',
  backgroundColor: 'transparent'
}

export const ToolbarSx = (theme: Theme) => ({
  gap: '15px',
  [theme.breakpoints.up('sm')]: {
    gap: '40px'
  },
  '& img': {
    width: '80px',
    height: '80px',
    [theme.breakpoints.up('sm')]: {
      width: '100px',
      height: '100px'
    }
  }
})

export const TypographyAppTitleSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 600,
  textShadow: '1px 1px #6e15b2',
  textTransform: 'uppercase',
  letterSpacing: '2px',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(28),
    letterSpacing: '3px'
  }
})

export const ContainerSx = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px'
}

export const TypographyFormTitleSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  textShadow: '1px 1px #226a91',
  letterSpacing: '2px',
  color: '#fff',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(24)
  }
})

export const PaperSx = (theme: Theme) => ({
  maxWidth: '360px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  marginBottom: '20px',
  paddingInline: '30px',
  paddingBlock: '20px',
  [theme.breakpoints.up('sm')]: {
    paddingBlock: '40px'
  }
})

export const TypographyFormHeadingSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textTransform: 'uppercase',
  textAlign: 'center',
  color: '#738089',

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.typography.pxToRem(18)
  }
})

export const StackButtonsSx = {
  display: 'inline-flex',
  flexDirection: 'column',
  gap: '8px'
}

export const ButtonSx = (theme: Theme) => ({
  alignSelf: 'flex-start',
  minWidth: 0,
  display: 'inline',
  padding: 0,
  fontSize: theme.typography.pxToRem(12),
  textTransform: 'uppercase'
})

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSidenavOpen: true
}

const sidenavSlice = createSlice({
  name: 'sidenav',
  initialState,
  reducers: {
    toggleSidenav: (state) => {
      state.isSidenavOpen = !state.isSidenavOpen
    }
  }
})

export default sidenavSlice.reducer
export const { toggleSidenav } = sidenavSlice.actions

import { Box, LinearProgress } from '@mui/material'

const LinearProgressIndicator = () => {
  return (
    <Box sx={{ width: '100%', marginTop: '5px' }}>
      <LinearProgress />
    </Box>
  )
}

export default LinearProgressIndicator

import React from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppBar,
  Button,
  Container,
  Paper,
  Stack,
  Toolbar,
  Typography
} from '@mui/material'
import LoginForm from './components/login-form'
import ForgotPasswordForm from './components/forgot-password-form'
import UnlockAccountForm from './components/unlock-account-form'
import AppLogo from 'src/assets/brand/new_lnp_logo.png'
import { toggleLoginPageForm } from 'src/setup/features/toggle-loginpage-form/toggleLoginPageFormSlice'
import {
  StackSx,
  AppBarSx,
  ToolbarSx,
  ContainerSx,
  TypographyAppTitleSx,
  TypographyFormTitleSx,
  TypographyFormHeadingSx,
  PaperSx,
  StackButtonsSx,
  ButtonSx
} from './sx-props'
import useSession from 'src/hooks/useSession'

const formVariations = [
  {
    id: 1,
    title: 'Login',
    component: <LoginForm />,
    buttonLabel: 'Sign In',
    value: 'sign-in',
    buttons: [
      {
        label: 'Forgot Password?',
        action: 'forgot-password'
      },
      {
        label: "Didn't receive unlock instructions?",
        action: 'unlock-account'
      }
    ]
  },
  {
    id: 2,
    title: 'Forgot Password',
    component: <ForgotPasswordForm />,
    value: 'forgot-password',
    buttons: [
      {
        label: 'Back to login',
        action: 'sign-in'
      },
      {
        label: "Didn't receive unlock instructions?",
        action: 'unlock-account'
      }
    ]
  },
  {
    id: 3,
    title: 'Unlock Account',
    component: <UnlockAccountForm />,
    value: 'unlock-account',
    buttons: [
      {
        label: 'Back to login',
        action: 'sign-in'
      },
      {
        label: 'Forgot Password?',
        action: 'forgot-password'
      }
    ]
  }
]

type ReduxStore = {
  login_page_form: {
    formToDisplay: string
  }
}

const LoginPage = () => {
  const formToDisplay = useSelector(
    (state: ReduxStore) => state.login_page_form.formToDisplay
  )
  const dispatch = useDispatch()
  const isSessionExpired = useSession()

  if (!isSessionExpired) return <Navigate to="/" />

  return (
    <Stack sx={StackSx}>
      <AppBar position="static" sx={AppBarSx}>
        <Toolbar sx={ToolbarSx}>
          <img src={AppLogo} alt="App Logo" />
          <Typography variant="h1" sx={TypographyAppTitleSx}>
            Ang Ligaya ng Panginoon
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={ContainerSx}>
        <Typography variant="h2" sx={TypographyFormTitleSx}>
          Members' Database System
        </Typography>
        <Paper elevation={6} sx={PaperSx}>
          {formVariations.map((form) => {
            const { id, title, component, value, buttons } = form

            if (formToDisplay !== value) return null

            return (
              <React.Fragment key={id}>
                <Typography variant="h3" sx={TypographyFormHeadingSx}>
                  {title}
                </Typography>
                {component}
                <Stack sx={StackButtonsSx}>
                  {buttons.map((button, idx) => {
                    const { label, action } = button

                    return (
                      <Button
                        key={idx}
                        onClick={() => dispatch(toggleLoginPageForm(action))}
                        sx={ButtonSx}
                      >
                        {label}
                      </Button>
                    )
                  })}
                </Stack>
              </React.Fragment>
            )
          })}
        </Paper>
      </Container>
    </Stack>
  )
}

export default LoginPage

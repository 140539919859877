import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useMutation } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { AxiosResponse } from 'axios'
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  IconButton,
  FormControlLabel,
  FormGroup,
  FormHelperText
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyIcon from '@mui/icons-material/Key'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { postLoginDetails } from 'src/setup/utils/post-requests'
import Spinner from 'src/common/spinner'
import {
  BoxTextFieldSx,
  FormGroupSx,
  TextFieldSx,
  TextFieldIconSx,
  TextFieldPasswordVisibilityIcon,
  FormControlLabelSx,
  FormHelperTextSx
} from '../shared-sx/sx-props'
import SnackbarNotifications from 'src/common/snackbar-notifications'
import useSnackbar from 'src/hooks/useSnackbar'

const initialValues = {
  username: '',
  password: '',
  rememberMe: false
}

const validationSchema = Yup.object({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
  rememberMe: Yup.boolean()
})

type ValuesTypes = {
  username: string
  password: string
  rememberMe: boolean
}
type OnSubmitPropsTypes = {
  resetForm: () => void
  setSubmitting: (params: boolean) => void
}

type OnSubmitParams = {
  values: ValuesTypes
  onSubmitProps: OnSubmitPropsTypes
  mutateLoginDetails: Function
  navigate: Function
  redirectPath: string
  toggleSnackbar: ({
    open,
    severity,
    message
  }: {
    open: boolean
    severity: 'info' | 'success' | 'warning' | 'error'
    message: string
  }) => void
}

const onSubmit = (params: OnSubmitParams) => {
  const {
    values: { username, password, rememberMe },
    onSubmitProps,
    mutateLoginDetails,
    navigate,
    redirectPath,
    toggleSnackbar
  } = params

  mutateLoginDetails(
    {
      username,
      password
    },
    {
      onSuccess: (response: AxiosResponse) => {
        // Setting api_key in local storage with ttl
        const oneWeek = 604800000
        const threeHours = 10800000

        const sessionKey = {
          value: response.data.api_key,
          expiryDate: Date.now() + (rememberMe ? oneWeek : threeHours)
        }

        const userInfo = {
          username: response.data.username,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email
        }

        localStorage.setItem(
          'lnp_user',
          JSON.stringify({
            id: response.data?.id,
            token: sessionKey,
            userInfo,
            sidenavs: response.data?.side_navs,
            settings: response.data?.settings,
          })
        )

        onSubmitProps.resetForm()
        setTimeout(() => {
          navigate(redirectPath, { replace: true })
        }, 800)
      },
      onError: (error: Error) => {
        toggleSnackbar({
          open: true,
          severity: 'error',
          message: error?.message
        })
      }
    }
  )
  onSubmitProps.setSubmitting(false)
}

type FieldTypes = {
  name: string
  value: string
  onChange: () => void
  onBlur: () => void
}

type MetaTypes = {
  error: string
  touched: boolean
}

type FieldProps = {
  field: FieldTypes
  meta: MetaTypes
}

const LoginForm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const stateLocation = location.state as { path: string }
  const redirectPath = stateLocation?.path || '/'
  const [showPassword, setShowPassword] = useState(false)
  const { snackbar, toggleSnackbar, closeSnackbar } = useSnackbar()
  const {
    mutate: mutateLoginDetails,
    isLoading,
    error,
    isError,
    isSuccess
  } = useMutation(postLoginDetails)

  const errorMessage =
    isError
      ? (error as Error).message === 'Request failed with status code 404'
        ? null
        : 'Invalid Username and/or Password'
      : null

  const togglePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, onSubmitProps) =>
          onSubmit({
            values,
            onSubmitProps,
            mutateLoginDetails,
            navigate,
            redirectPath,
            toggleSnackbar
          })
        }
      >
        {(formik) => {
          return (
            <Form>
              <FormGroup sx={FormGroupSx}>
                <Field name="username">
                  {(props: FieldProps) => {
                    const {
                      field,
                      meta: { error, touched }
                    } = props

                    return (
                      <>
                        <Box sx={BoxTextFieldSx}>
                          <AccountCircle sx={TextFieldIconSx} />
                          <TextField
                            id="username"
                            label="Username"
                            type="text"
                            variant="standard"
                            sx={TextFieldSx}
                            helperText={error && touched ? error : ' '}
                            autoFocus
                            {...field}
                          />
                        </Box>
                      </>
                    )
                  }}
                </Field>

                <Field name="password">
                  {(props: FieldProps) => {
                    const {
                      field,
                      meta: { error, touched }
                    } = props

                    return (
                      <Box sx={BoxTextFieldSx}>
                        <KeyIcon sx={TextFieldIconSx} />
                        <TextField
                          id="password"
                          label="Password"
                          type={showPassword ? 'text' : 'password'}
                          variant="standard"
                          sx={TextFieldSx}
                          helperText={error && touched ? error : ' '}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={togglePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                  sx={TextFieldPasswordVisibilityIcon}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          {...field}
                        />
                      </Box>
                    )
                  }}
                </Field>

                <Field name="rememberMe">
                  {(props: { field: { value: boolean } }) => {
                    const { field } = props

                    return (
                      <FormControlLabel
                        label="Remember me"
                        control={
                          <Checkbox
                            size="small"
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        sx={FormControlLabelSx}
                      />
                    )
                  }}
                </Field>

                <FormHelperText error={isError} sx={FormHelperTextSx}>
                  {isError ? errorMessage : isSuccess ? 'Login Success' : null}
                </FormHelperText>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Login
                </Button>
              </FormGroup>
            </Form>
          )
        }}
      </Formik>
      <Spinner open={isLoading} />
      {snackbar.open && (
        <SnackbarNotifications
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          handleClose={closeSnackbar}
        />
      )}
    </>
  )
}

export default LoginForm

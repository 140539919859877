import { Theme } from '@mui/material'
import ToolBarBg from 'src/assets/images/lnp-banner.jpg'

export const AppBarSx = (theme: Theme) => ({
  zIndex: 1300
})

export const ToolbarSx = (theme: Theme, isSidenavOpen: boolean) => ({
  justifyContent: 'space-between',
  backgroundImage: `url(${ToolBarBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.up('md')]: {
    minHeight: '70px'
  },
})

export const StackWebTitleLogoSx = (theme: Theme) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '10px',
  [theme.breakpoints.up('md')]: {
    gap: '20px'
  },
  '& > img': {
    width: '60px',
    height: '60px',
    [theme.breakpoints.up('md')]: {
      width: '70px',
      height: '70px',
      gap: '20px'
    }
  }
})

export const TypographyAppTitleSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 500,
  textTransform: 'uppercase',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(32)
  }
})

export const StackGreetingsAvatarSx = (theme: Theme) => ({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '10px'
})

export const TypographyGreetingsSx = (theme: Theme) => ({
  display: 'none',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: '#fff',
  textTransform: 'capitalize',

  [theme.breakpoints.up('sm')]: {
    display: 'block'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 900 
  }
})

export const AvatarSx = {
  width: '30px',
  height: '30px'
}

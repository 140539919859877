import { useState } from 'react'

type SnackbarStateType = {
  open: boolean
  severity: 'info' | 'success' | 'warning' | 'error'
  message: string
}

const initialSnackbarValues: SnackbarStateType = {
  open: false,
  severity: 'info',
  message: ''
}

const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState(initialSnackbarValues)
  const toggleSnackbar = ({ open, severity, message }: SnackbarStateType) =>
    setSnackbar({
      open,
      severity,
      message
    })
  const closeSnackbar = () => setSnackbar(initialSnackbarValues)

  return {
    snackbar,
    toggleSnackbar,
    closeSnackbar
  }
}

export default useSnackbar

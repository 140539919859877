import { request } from './axios-interceptor'

type PostLoginDataTypes = {
  username: string
  password: string
}
export const postLoginDetails = (data: PostLoginDataTypes) =>
  request({ url: '/v1/users/login', method: 'post', data })

type PostForgotPasswordDetails = {
  username: string
}
export const postForgotPasswordDetails = (data: PostForgotPasswordDetails) =>
  request({ url: '/v1/users/passwords', method: 'post', data })

export const postNewChurchAffiliates = (data: { id: number; name: string }) =>
  request({
    url: '/v1/church_affiliates',
    method: 'post',
    data: { name: data.name }
  })

export const postNewClassifications = (data: { id: number; name: string }) =>
  request({
    url: '/v1/classifications',
    method: 'post',
    data: { name: data.name }
  })

export const postNewCategories = (data: { id: number; name: string }) =>
  request({
    url: '/v1/categories',
    method: 'post',
    data: { name: data.name }
  })

export const postNewEntrypoints = (data: { id: number; name: string }) =>
  request({
    url: '/v1/entrypoints',
    method: 'post',
    data: { name: data.name }
  })

export const postNewStatuses = (data: { id: number; name: string }) =>
  request({
    url: '/v1/statuses',
    method: 'post',
    data: { name: data.name }
  })

export const postNewServiceTypes = (data: { id: number; name: string }) =>
  request({
    url: '/v1/service_types',
    method: 'post',
    data: { name: data.name }
  })

export const postNewAttendanceNames = (data: { id: number; name: string }) =>
  request({
    url: '/v1/attendance_names',
    method: 'post',
    data: { name: data.name }
  })

export const postNewSectors = (data: {
  id: number
  name: string
  short_name: string
  ancestry: string | null
}) =>
  request({
    url: '/v1/sectors',
    method: 'post',
    data: {
      name: data.name,
      short_name: data.short_name,
      parent_id: data.ancestry !== null ? Number(data.ancestry) : data.ancestry
    }
  })

export const postNewProphecyEventNames = (data: {
  id: number
  name: string
  roles: number[]
}) =>
  request({
    url: '/v1/prophecy_event_names',
    method: 'post',
    data: {
      name: data.name,
      role_ids: data.roles
    }
  })

export const postNewMembers = (data: {
  id: string | number
  ligayaMember: boolean
  firstName: string
  middleName: string
  lastName: string
  suffixName: string
  nickname: string
  gender: string
  civilStatus: string
  bloodType: string
  birthDate: string
  weddingDate: string
  profilePhoto: any
  parentFather: string
  parentFatherName: string
  parentMother: string
  parentMotherName: string
  contactNo: {
    id: string | number
    _destroy?: boolean
    category: string
    number: string
  }[]
  email: {
    id: string | number
    category: string
    email: string
  }[]
  address: {
    id: string | number
    _destroy?: boolean
    category: string
    street: string
    barangay: string
    city: string
    province: string
    latitude: string
    longitude: string
  }[]
  company: string
  profession: string
  occupation: string
  school: string
  schoolLevel: string
  course: string
  communityWeekend: string
  entrypoint: string
  sectorDistrict: string
  churchAffiliation: string
  batch: string
  service: {
    id: string | number
    _destroy?: boolean
    isActive: boolean
    serviceType: string
    remarks: string
    monthStarts: string
    yearStarts: string
    monthEnds: string
    yearEnds: string
    dateRemarks: string
  }[]
  classifications: {
    id: string | number
    _destroy?: boolean
    isActive: boolean
    classification: string
    date: string
    year: string
  }[]
  status: {
    id: string | number
    _destroy?: boolean
    status: string
    date: string
    year: string
  }[]
}) => {
  const { contactNo, email, address, service, status } = data

  const contactNumbers = contactNo.length
    ? contactNo?.map((contact) => ({
        id: '',
        reference_type: 'Lnp::Member',
        category: Number(contact.category),
        content: contact.number
      }))
    : []

  const emailAddresses = email.length
    ? email?.map((email) => ({
        reference_type: 'Lnp::Member',
        category: Number(email.category),
        content: email.email
      }))
    : []

  const addresses = address.length
    ? address.map((addressObj) => ({
        reference_type: 'Lnp::Member',
        category: Number(addressObj.category),
        street: addressObj.street,
        brgy: addressObj.barangay,
        city: addressObj.city,
        state: addressObj.province,
        latitude: addressObj.latitude,
        longitude: addressObj.longitude
      }))
    : []

  const services = service.length
    ? service.map((serviceObj) => ({
        is_active: serviceObj.isActive,
        month_to: serviceObj.monthEnds,
        year_to: serviceObj.yearEnds,
        month_from: serviceObj.monthStarts,
        year_from: serviceObj.yearStarts,
        date_remarks: serviceObj.dateRemarks,
        service_type_id: Number(serviceObj.serviceType),
        remarks: serviceObj.remarks
      }))
    : []

  const statusesAttrib = status.length
    ? status.map((statusObj) => ({
        date_effective: statusObj.date,
        reference_id: statusObj.status,
        reference_type: 'Lnp::Status',
        year: statusObj.year,
        remarks: ''
      }))
    : []

  // const classificationsAttrib = classifications.length
  //   ? classifications.map((classificationObj) => ({
  //       date_effective: classificationObj.date,
  //       is_active: classificationObj.isActive,
  //       reference_id: classificationObj.classification,
  //       reference_type: 'Lnp::Classification',
  //       year: classificationObj.year
  //     }))
  //   : []

  return request({
    url: 'v1/members',
    method: 'post',
    data: {
      id: data.id,
      is_active: true,
      blood_type: data.bloodType,
      wedding_date: data.weddingDate,
      company: data.company,
      profession: data.profession,
      occupation: data.occupation,
      school: data.school,
      school_level: data.schoolLevel,
      course: data.course,
      district_role: '',
      sector_id: Number(data.sectorDistrict),
      // classification_id: ,
      community_weekend: data.communityWeekend,
      // status: ,
      // avatar: null,
      mother_id: Number(data.parentMother),
      father_id: Number(data.parentFather),
      // child_id: ,
      convent_year: '',
      batch_year: data.batch,
      church_affiliate_id: Number(data.churchAffiliation),
      entrypoint_id: Number(data.entrypoint),
      is_member: !data.ligayaMember,
      profile_attributes: {
        id: '',
        first_name: data.firstName,
        middle_name: data.middleName,
        last_name: data.lastName,
        suffix_name: data.suffixName,
        nickname: data.nickname,
        birth_date: data.birthDate,
        gender: data.gender,
        birth_place: '',
        civil_status: data.civilStatus
      },
      contact_numbers_attributes: contactNumbers,
      email_addresses_attributes: emailAddresses,
      addresses_attributes: addresses,
      history_members_attributes: statusesAttrib,
      services_attributes: services
    }
  })
}

export const postNewSchedules = (data: {
  id: number | string
  cluster: string
  coordinatorId: number
  courseLeader: string
  dateStartsAt: string
  dateEndsAt: string
  referenceId: number
  remarks: string
  timeStartsAt: string
  venue: string
  is_status: 'active' | 'done' | 'cancel'
  notesAttributes: {
    id?: number
    _destroy?: boolean
    reference_id?: number
    remarks: string
    date_start: string
  }[]
}) =>
  request({
    url: '/v1/schedules',
    method: 'post',
    data: {
      id: '',
      cluster: data.cluster,
      coordinator_id: data.coordinatorId,
      course_leader: data.courseLeader,
      date_starts_at: data.dateStartsAt,
      date_ends_at: data.dateEndsAt,
      reference_id: data.referenceId,
      reference_type: 'Lnp::Course',
      remarks: data.remarks,
      time_starts_at: data.timeStartsAt,
      venue: data.venue,
      notes_attributes: data.notesAttributes,
      is_active: '',
      is_status: 'active'
    }
  })

export const postNewAttendances = (data: {
  id: number | string
  attendanceName: string
  attendanceId: number
  attendanceType: string
  dateAttend: string
  memberIds: number[]
  remarks: string
  sectorId: number
  venue: string
}) =>
  request({
    url: '/v1/attendances',
    method: 'post',
    data: {
      id: '',
      attendance_name: data.attendanceName,
      attendance_name_id: data.attendanceId,
      attendance_type: data.attendanceType,
      date_attend: data.dateAttend,
      member_ids: data.memberIds,
      remarks: data.remarks,
      sector_id: data.sectorId,
      venue: data.venue
    }
  })

export const postNewAnnouncement = (data: {
  scheduleId: number
  title: string
  content: string
  publish: boolean
  member_ids: number[]
  file: FormData[]
}) =>
  request({
    url: `v1/schedules/${data.scheduleId}/announcements`,
    method: 'post',
    data: {
      title: data.title,
      content: data.content,
      publish: data.publish,
      member_ids: data.member_ids,
      file: []
    }
  })

export const postNewChangeSector = (data: {
  id: number | string
  referenceId: number
  memberId: number
  dateEffective: string
  oldSectorId: number
  sectorId: number
}) =>
  request({
    url: 'v1/change_sectors',
    method: 'post',
    data: {
      reference_type: 'Lnp::Member',
      reference_id: data.referenceId,
      member_id: data.memberId,
      date_effective: data.dateEffective,
      old_sector_id: data.oldSectorId,
      sector_id: data.sectorId
    }
  })

export const postChangeSectorRequestStatus = (data: {
  id: number
  transferStatus: string
}) =>
  request({
    url: `v1/request_statuses/${data.id}/${data.transferStatus}`,
    method: 'post'
  })

export const postNewGroup = (data: {
  id: number | string
  groupMemberAttributes: {
    id: number | string
    member_id: number
    role: string
    _destroy: boolean
  }[]
  groupSectorsAttributes: {
    id: number | string
    sector_id: number
    _destroy: boolean
  }[]
}) =>
  request({
    url: 'v1/groups',
    method: 'post',
    data: {
      group_members_attributes: data.groupMemberAttributes,
      group_sectors_attributes: data.groupSectorsAttributes
    }
  })

export const postNewProphecy = (data: {
  id: number | string
  date_prophecy: string
  prophecy_type: string
  speaker: string
  sector_id: number
  prophecy_event_name_id: string | number
  prophecy_event_name: string
  remarks: string
  content: string
}) =>
  request({
    url: 'v1/prophecies',
    method: 'post',
    data: {
      id: data.id,
      date_prophecy: data.date_prophecy,
      prophecy_type: data.prophecy_type,
      speaker: data.speaker,
      sector_id: data.sector_id,
      prophecy_event_name_id: data.prophecy_event_name_id,
      prophecy_event_name: data.prophecy_event_name,
      remarks: data.remarks,
      content: data.content
    }
  })

export const postNewCourse = (data: {
  id: number | string
  parent: number | null
  name: string
  shortName: string
  rank: number | null
  description: string
  talkNo: string | null
  isOptional: boolean
  courseLink: string
  participantLink: string
  videoLink: string
}) =>
  request({
    url: 'v1/courses',
    method: 'post',
    data: {
      id: data.id,
      parent_id: data.parent,
      rank: data.rank,
      name: data.name,
      short_name: data.shortName,
      description: data.description,
      talk_no: data.talkNo,
      is_optional: data.isOptional,
      course_link: data.courseLink,
      participant_link: data.participantLink,
      video_link: data.videoLink
    }
  })

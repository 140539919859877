import { Theme } from '@mui/material'

export const FormGroupInputSx = (theme: Theme) => ({
  '& > *': {
    marginBottom: '5px'
  },
  '& .MuiInputLabel-root, .MuiInputBase-root': {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  '& .MuiFormHelperText-root': {
    color: 'red',
    fontSize: theme.typography.pxToRem(11)
  }
})

export const FormGroupButtonSx = (theme: Theme) => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '5px',
  '& .MuiButtonBase-root': {
    fontSize: theme.typography.pxToRem(12),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  }
})

export const StackHeaderSx = (theme: Theme) => ({
  paddingBottom: '20px',
  alignItems: 'center'
})

export const TypographyLabelSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(12)
})

export const IconSx = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(60)
})

export const TextFieldSx = (theme: Theme) => ({
  '& .MuiInput-underline': {
    fontSize: theme.typography.pxToRem(13),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  '& .MuiInputLabel-root': {
    fontSize: theme.typography.pxToRem(13),

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  '& .MuiFormHelperText-root': {
    marginInline: '2px',
    color: 'red'
  }
})

export const TextFieldPasswordVisibilityIconSx = {
  margin: 0,
  '& .MuiSvgIcon-root': {
    fontSize: '20px'
  }
}
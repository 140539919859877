import React from 'react'
import { Alert, Snackbar } from '@mui/material'

type SnackbarNotificationProps = {
  severity: 'success' | 'error' | 'warning' | 'info'
  message: string
  open: boolean
  handleClose: () => void
}

const SnackbarNotification = (props: SnackbarNotificationProps) => {
  const { severity, message, open = false, handleClose } = props

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default React.memo(SnackbarNotification)

import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Divider, Drawer, List, Toolbar } from '@mui/material'
import { listItems } from './list-items'
import NestedListItem from './components/nested-list-item'
import { DrawerSx, ToolbarSx } from './sx-props'

type ReduxStoreType = {
  sidenav: {
    isSidenavOpen: boolean
  }
}

type defaultListTypes = ({
  id: number;
  title: string;
  key: string;
  link: string;
  icon: JSX.Element;
  subItems?: undefined;
} | {
  id: number;
  title: string;
  icon: JSX.Element;
  subItems: {
      id: number;
      title: string;
      key: string;
      icon: JSX.Element;
      link: string;
  }[];
  key?: undefined;
  link?: undefined;
})[]

const filterToUserRoutes = (defaultList: defaultListTypes) => {
  const user = JSON.parse(localStorage.getItem('lnp_user')!)
  const userRoutes = [...user?.sidenavs, ...user?.settings]

  const filteredParentItems = listItems.filter(
    (item) =>
      item.subItems ||
      item.key === 'default_show' ||
      userRoutes.includes(item.key)
  )

  const filteredSubItems = filteredParentItems.map((item) => {
    if (item.subItems) {
      // default show condition, just to display members list
      const newSubItems = item.subItems.filter((item) =>
        userRoutes.includes(item.key) || item.key === 'default_show'
      )

      return {
        ...item,
        subItems: newSubItems
      }
    }

    return item
  })

  const filteredParentWithSubItems = filteredSubItems.filter((item) =>
    item.subItems ? (item.subItems.length ? true : false) : true
  )

  return filteredParentWithSubItems
}

const SideNav = () => {
  const isSidenavOpen = useSelector(
    (store: ReduxStoreType) => store.sidenav.isSidenavOpen
  )

  const userRoutes = filterToUserRoutes(listItems)

  return (
    <Drawer
      variant="persistent"
      open={isSidenavOpen}
      anchor="left"
      sx={(theme) => DrawerSx(theme, isSidenavOpen)}
    >
      <Toolbar sx={ToolbarSx} />
      <Box sx={{ overflow: 'auto', overscrollBehavior: 'contain' }}>
        <List>
          {userRoutes.map((item) => {
            const { id, title } = item

            return (
              <React.Fragment key={id}>
                <Divider />
                <NestedListItem
                  title={title}
                  icon={item.icon}
                  link={item.link}
                  subItems={item.subItems}
                />
              </React.Fragment>
            )
          })}
          <Divider />
        </List>
      </Box>
    </Drawer>
  )
}

export default SideNav

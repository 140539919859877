import axios, { AxiosResponse } from 'axios'

const client = axios.create({ baseURL: process.env.REACT_APP_API_DOMAIN })

export const request = ({ ...options }) => {
  if (!options.method)
    client.defaults.headers.common['X-API-ACCESS-TOKEN'] = JSON.parse(
      localStorage.getItem('lnp_user')!
    ).token.value

  const onSuccess = (response: AxiosResponse) => response
  // const onError = (error: AxiosError) => {
  //   // logout user
  //   return error
  // }

  return client(options).then(onSuccess)
}

import HomeIcon from '@mui/icons-material/Home'
import PeopleIcon from '@mui/icons-material/People'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import GroupsIcon from '@mui/icons-material/Groups'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import BookIcon from '@mui/icons-material/Book'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import EmailIcon from '@mui/icons-material/Email'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import NaturePeopleIcon from '@mui/icons-material/NaturePeople'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SettingsIcon from '@mui/icons-material/Settings'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import ReorderIcon from '@mui/icons-material/Reorder'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate'
import RoomServiceIcon from '@mui/icons-material/RoomService'
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup'
import EventNoteIcon from '@mui/icons-material/EventNote'
import DateRangeIcon from '@mui/icons-material/DateRange'
import BadgeIcon from '@mui/icons-material/Badge'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import TimelineIcon from '@mui/icons-material/Timeline'
import ListIcon from '@mui/icons-material/List';

export const listItems = [
  {
    id: 0,
    title: 'Home',
    key: 'default_show',
    link: '/',
    icon: <HomeIcon fontSize='small' />
  },
  {
    id: 1,
    title: 'Members',
    icon: <PeopleIcon fontSize='small' />,
    subItems: [
      {
        id: 1.1,
        title: 'Profiles',
        key: 'profile',
        icon: <AccountBoxIcon fontSize='small' />,
        link: '/members'
      },
      {
        id: 1.2,
        title: 'Attendance to Events',
        key: 'attendance',
        icon: <EventAvailableIcon fontSize='small' />,
        link: '/attendances'
      },
      {
        id: 1.3,
        title: 'MG/WG Groupings',
        key: 'group',
        icon: <GroupsIcon fontSize='small' />,
        link: '/groups'
      },
      {
        id: 1.4,
        title: 'Transfer',
        key: 'transfer',
        icon: <TransferWithinAStationIcon fontSize='small' />,
        link: '/change_sectors'
      },
      {
        id: 1.5,
        title: "Members' List",
        key: 'default_show',
        icon: <ListIcon fontSize='small' />,
        link: '/member_lists'
      }
    ]
  },
  {
    id: 2,
    title: 'Formation Courses',
    icon: <BookIcon fontSize='small' />,
    subItems: [
      {
        id: 2.1,
        title: 'Course Offerings',
        key: 'schedule',
        icon: <InboxIcon fontSize='small' />,
        link: '/schedules'
      },
      {
        id: 2.2,
        title: 'Course Materials',
        key: 'course',
        icon: <EmailIcon fontSize='small' />,
        link: '/courses'
      }
    ]
  },
  {
    id: 3,
    title: 'Prophecies',
    key: 'prophecy',
    icon: <TrackChangesIcon fontSize='small' />,
    link: '/prophecies'
  },
  {
    id: 4,
    title: "Leaders' References",
    key: 'form',
    icon: <NaturePeopleIcon fontSize='small' />,
    link: '/forms'
  },
  {
    id: 5,
    title: 'FAQs',
    key: 'default_show',
    icon: <QuestionAnswerIcon fontSize='small' />,
    link: '/faqs'
  },
  {
    id: 6,
    title: 'Settings',
    icon: <SettingsIcon fontSize='small' />,
    subItems: [
      {
        id: 6.1,
        title: 'Church Affiliation',
        key: 'church_affiliate',
        icon: <SupervisedUserCircleIcon fontSize='small' />,
        link: '/church_affiliates'
      },
      {
        id: 6.2,
        title: 'Classifications',
        key: 'classification',
        icon: <ReorderIcon fontSize='small' />,
        link: '/classifications'
      },
      {
        id: 6.3,
        title: 'Sub Category',
        key: 'category',
        icon: <FormatListBulletedIcon fontSize='small' />,
        link: '/categories'
      },
      {
        id: 6.4,
        title: 'Entry Points',
        key: 'entry_point',
        icon: <MeetingRoomIcon fontSize='small' />,
        link: '/entrypoints'
      },
      {
        id: 6.5,
        title: 'Statuses',
        key: 'status',
        icon: <AssignmentLateIcon fontSize='small' />,
        link: '/statuses'
      },
      {
        id: 6.6,
        title: 'Service Types',
        key: 'service_type',
        icon: <RoomServiceIcon fontSize='small' />,
        link: '/service_types'
      },
      {
        id: 6.7,
        title: 'Sectors',
        key: 'sector',
        icon: <SpeakerGroupIcon fontSize='small' />,
        link: '/sectors'
      },
      {
        id: 6.8,
        title: 'Event Name',
        key: 'event_nane',
        icon: <EventNoteIcon fontSize='small' />,
        link: '/attendance_names'
      },
      {
        id: 6.9,
        title: 'Prophecy Event Name',
        key: 'prophecy_event_name',
        icon: <DateRangeIcon fontSize='small' />,
        link: '/prophecy_event_names'
      },
      {
        id: 6.11,
        title: 'Employees',
        key: 'employee',
        icon: <BadgeIcon fontSize='small' />,
        link: '/employees'
      },
      {
        id: 6.12,
        title: 'Role Management',
        key: 'active_member',
        icon: <RecordVoiceOverIcon fontSize='small' />,
        link: '/active_members'
      },
      {
        id: 6.13,
        title: 'Record History',
        key: 'record_history',
        icon: <TimelineIcon fontSize='small' />,
        link: '/record_histories'
      }
    ]
  }
]

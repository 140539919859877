import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material'

type Props = {
  open: boolean
  title: string
  content: string
  item: string
  toggle: () => void
  confirm: () => void
}

const ConfirmationModal = (props: Props) => {
  const { open, title, content, item, toggle, confirm } = props

  let message: React.ReactNode
  const styledItem = (
    <Typography
      variant="caption"
      sx={{ fontWeight: 600, textTransform: 'capitalize', fontSize: 'inherit' }}
    >
      {item}
    </Typography>
  )

  switch (title) {
    case 'Delete':
      message = item ? (
        <>
          Are you sure you want to delete this {content} - {styledItem} ?
        </>
      ) : (
        <>Are you sure you want to delete this {content} ?</>
      )
      break
    case 'Transfer':
      message = (
        <>
          Are you sure you want to {content} the transfer of {styledItem} ?
        </>
      )
      break
    default:
      message = content
  }

  return (
    <Dialog open={open} onClose={toggle} maxWidth="sm">
      <DialogTitle sx={(theme) => ({ fontSize: theme.typography.pxToRem(16) })}>
        {title}
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({ fontSize: theme.typography.pxToRem(14) })}
      >
        {message}
      </DialogContent>
      <DialogActions sx={{ paddingInline: '24px', paddingBottom: '16px' }}>
        <Button
          onClick={toggle}
          variant="text"
          color="info"
          sx={(theme) => ({ fontSize: theme.typography.pxToRem(12) })}
        >
          Cancel
        </Button>
        <Button
          onClick={confirm}
          variant="outlined"
          color="error"
          sx={(theme) => ({ fontSize: theme.typography.pxToRem(12) })}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal

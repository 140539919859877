import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import {
  ListItemMainSx,
  ListItemButtonMainSx,
  ListItemTextMainSx,
  ListItemButtonSx,
  ListSx
} from './sx-props'

type subItems = {
  id: number
  title: string
  icon?: React.ReactNode
  link?: string
  subItems?: subItems
}[]

type nestedListItemProps = {
  title: string
  icon?: React.ReactNode
  link?: string
  subItems?: subItems
}

const NestedListItem = (props: nestedListItemProps) => {
  const { title, link, icon, subItems } = props
  const [isCollapsible, setIsCollapsible] = useState(false)

  const handleCollapseToggle = () => setIsCollapsible(!isCollapsible)

  return (
    <ListItem sx={ListItemMainSx}>
      {link ? (
        <NavLink to={link}>
          <ListItemButton sx={ListItemButtonMainSx}>
            <ListItemIcon sx={{ minWidth: '0px' }}>{icon}</ListItemIcon>
            <ListItemText sx={ListItemTextMainSx}>{title}</ListItemText>
          </ListItemButton>
        </NavLink>
      ) : (
        <ListItemButton onClick={handleCollapseToggle} sx={ListItemButtonSx}>
          <ListItemIcon sx={{ minWidth: '0px' }}>{icon}</ListItemIcon>
          <ListItemText sx={ListItemTextMainSx}>{title}</ListItemText>
          {isCollapsible ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      {subItems && (
        <Collapse
          in={isCollapsible}
          timeout="auto"
          unmountOnExit
          sx={{ width: '100%', marginTop: '10px' }}
        >
          <List sx={ListSx}>
            {subItems.map((subItem) => {
              const { id, title } = subItem

              return (
                <NestedListItem
                  title={title}
                  icon={subItem.icon}
                  link={subItem.link}
                  key={id}
                />
              )
            })}
          </List>
        </Collapse>
      )}
    </ListItem>
  )
}

export default NestedListItem

import { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Spinner from 'src/common/spinner'
import useSession from 'src/hooks/useSession'

type PropType = {
  children: JSX.Element
}

const RequireAuth = ({ children }: PropType) => {
  const location = useLocation()
  const [redirectNow, setRedirectNow] = useState(false)
  const isSessionExpired = useSession()

  const toLoginPage = (
    <Navigate to="/login" state={{ path: location.pathname }} />
  )

  if (!localStorage.getItem('lnp_user')) return toLoginPage

  if (isSessionExpired) {
    setTimeout(() => {
      localStorage.removeItem('lnp_user')
      setRedirectNow(true)
    }, 3000)
  }

  return (
    <>
      {isSessionExpired && (
        <Spinner open={true} message="Your session has expired..." />
      )}
      {redirectNow ? toLoginPage : children}
    </>
  )
}

export default RequireAuth
